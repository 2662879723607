// Returns a unique ID string
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function validateTestServer(server: string | null) {
  switch (server) {
    case 'ca':
      return 'https://ca.seamless.md';

    case 'ca-canary-2':
      return 'https://ca-canary-2.seamless.md';

    case 'ca-canary-3':
      return 'https://ca-canary-3.seamless.md';

    case 'usa':
      return 'https://usa.seamless.md';

    case 'usa-canary':
      return 'https://usa-canary.seamless.md';

    case 'app':
      return 'https://app.seamless.md';

    default:
      return null;
  }
}
